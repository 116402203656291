import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { URL_LIST } from '../../../constants/apiList';
import SimpleTable from '../../common/Tables/SimpleTable/SimpleTable';
import Loader from 'react-loader-spinner';
import styles from './CustomerWallet.module.css';
import { getBrand } from '../../../helpers/index';
import { Button } from '@material-ui/core';
function WalletPage(props) {
    const [walletData, setWalletData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [comment, setComment] = useState('');
    const [orderId, setOrderId] = useState('');
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(''); // success or error

    const query = new URLSearchParams(props.location.search);
    const email = query.get('email');

    const columns = [
        { title: 'Cashback/Spent Amount', field: 'cashback_amount' },
        { title: 'Roll Up Balance', field: 'prefixSum' },
        { title: 'Order Id', field: 'order_no' },
        { title: 'Code', field: 'type' },
        { title: 'Date', field: 'added_on' },
        { title: 'Expiry Date', field: 'expiryDate' },
        { title: 'Comment', field: 'comment' },
    ];

    useEffect(() => {
        const requestData = { email };
        const options = { headers: { brand: getBrand() } };

        setLoader(true);
        axios
            .post(URL_LIST.WALLET, requestData, options)
            .then((res) => {
                setWalletData(res.data);
            })
            .catch(() => {})
            .finally(() => {
                setLoader(false);
            });
    }, []);

    const handleModifyCashback = (event) => {
        event.preventDefault()
        // Validate the input
        if (amount === '') {
            setToastMessage('Please enter an amount');
            setToastType('error');
            setTimeout(() => setToastMessage(null), 3000);
            return;
        }

        if(orderId === ''){
            setToastMessage('Please enter a Order ID');
            setToastType('error');
            setTimeout(() => setToastMessage(null), 3000);
            return;
        }

        if (comment === '') {
            setToastMessage('Please enter a comment');
            setToastType('error');
            setTimeout(() => setToastMessage(null), 3000);
            return;
        }

        if (isNaN(amount) || parseInt(amount) === 0 || parseInt(amount) > 999) {
            setToastMessage('Please enter a valid integer non-zero amount less than 999');
            setToastType('error');
            setTimeout(() => setToastMessage(null), 3000);
            return;
        }

        const type = amount > 0 ? 'add' : 'remove'
        if(!window.confirm(`Are you sure you want to ${type} ${Math.abs(parseInt(amount, 10))} cashback?`)) {
            return false;
        }
        const requestData = { amount: parseInt(amount, 10), type, comment, email, orderId };
        const options = { headers: { brand: getBrand() } };

        setLoader(true);
        axios
            .post(URL_LIST.WALLET_MODIFY, requestData, options)
            .then((res) => {
                setToastMessage(`${res.data.message}`);
                if(res.data.status) {
                    setToastType('success');
                    window.location.reload();
                } else {
                    setToastType('error');
                }
            })
            .catch(() => {
                setToastMessage(`Failed to modify cashback, Please try again`);
                setToastType('error');
            })
            .finally(() => {
                setLoader(false);
                setAmount(''); // Reset amount input
                setComment(''); // Reset comment input
                setTimeout(() => setToastMessage(null), 3000); // Hide toast after 3 seconds
            });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setAmount(''); // Reset amount input
        setComment(''); // Reset comment input
    };

    return (
        <div>
            {loader ? (
                <div className={styles.loader}>
                    <Loader type="TailSpin" color="#00BFFF" height={40} width={40} />
                </div>
            ) : (
                <div className={styles['wallet-container']}>
                    <div className={styles['title-header']}>Customer Wallet Page</div>
                    <div className={styles['wallet-title']}>
                        <span className="amount">
                        Wallet Points: {walletData?.current || 0}
                        </span>
                        <Button variant='contained' color='primary' className='' onClick={() => setShowModal(true)}>Modify Cashback</Button>
                    </div>
                    {walletData?.history && (
                        <SimpleTable columns={columns} rows={walletData.history} />
                    )}

                    {showModal && (
                        <div className={styles['modal-overlay']}>
                            <div className={styles.modal}>
                                <h2>Modify Cashback</h2>
                                <form className={styles['form-container']} onSubmit={handleModifyCashback}>
                                <input
                                    className={styles.input}
                                    type="text"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder="Enter amount"
                                    required
                                />
                                <input
                                    className={styles.input}
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Enter Comment"
                                    required
                                />
                                <input
                                    className={styles.input}
                                    type="text"
                                    value={orderId}
                                    onChange={(e) => setOrderId(e.target.value)}
                                    placeholder="Enter Order ID"
                                />
                                <div className={styles['button-container']}>
                                    <Button
                                        type='button'
                                        variant='outlined'
                                        onClick={handleCloseModal}
                                        color="secondary"
                                    >
                                     Close
                                    </Button>
                                    <Button
                                            className={styles['action-button']}
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                        >
                                            Update Cashback
                                    </Button>
                                </div>
                               
                                
                                </form>
                            </div>
                        </div>
                    )}

                    {toastMessage && (
                        <div
                            className={`${styles.toast} ${
                                toastType === 'success'
                                    ? styles['toast-success']
                                    : styles['toast-error']
                            }`}
                        >
                            {toastMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default WalletPage;